<template>
    <div class="form-check" :class="pillShape ? 'form-check-pill' : ''">
        <input
            :id="idAttr"
            class="form-check-input cursor-pointer"
            :class="{ 'is-invalid': errorMessage }"
            :name="name"
            type="checkbox"
            :checked="checked"
            @change="onChange"
            :disabled="disabled">
        <label class="form-check-label" :for="idAttr">
            <slot>{{ label }}</slot>
            <Icon symbol="check" class="ms-2" :class="pillShape ? 'form-check-icon' : 'd-none'"></Icon>
        </label>
    </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { computed, PropType, toRef } from 'vue';
import kebabCase from 'lodash/kebabCase';
import { Icon } from '@/modules/core/components'

const emit = defineEmits<{
    (e: 'update:modelValue', value: any): void,
    (e: 'change'): void
}>();

const props = defineProps({
    id: String,
    name: { type: String, required: true },
    label: { type: String },
    value: { type: null, required: true },
    uncheckedValue: { type: null, defaultValue: undefined },
    valueToString: { type: Function as PropType<(value: any) => string> },
    disabled: Boolean,
    pillShape: Boolean
})

const isValuePrimitive = () => ['number', 'string', 'boolean'].includes(typeof props.value);

if (!isValuePrimitive() && !props.valueToString) {
    console.warn(`Please provide {valueToString} for {${props.name}} if {value} is not a primitive`);
}

const idAttr = computed(() => {
    if (props.id) return props.id;
    if (!props.value) return;

    if (!isValuePrimitive() && !props.valueToString) return;
    const value = isValuePrimitive() ? props.value : props.valueToString!(props.value);
    return 'chk-' + kebabCase(props.name) + '-' + kebabCase(value)
});

const {
    errorMessage,
    handleChange,
    checked,
} = useField(
    toRef(props, 'name'),
    undefined,
    {
        type: 'checkbox',
        checkedValue: props.value,
        uncheckedValue: props.uncheckedValue
    }
)

const onChange = () => {
    handleChange(checked ? props.value : props.uncheckedValue);
    emit('update:modelValue', checked);
    emit('change');
}
</script>
